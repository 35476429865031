/**
 * Created by kinsellad on 30/01/2017.
 */
define(['app', '$window', 'siteObj'], function(app, $window, siteObj) {

'use strict';

  var productBlock = function() {
    var self = this;
    const DATA_OPTION_ID = 'data-option-id';
    const DATA_LINKED_SWATCH = 'data-linked-swatch';
    const DATA_ALT_SRC = 'data-alt-src';
    const DATA_TRACK_HOVER = 'data-component-tracked-hovered';
    const DATA_TRACK_FOCUS = 'data-component-tracked-focused';
    const DATA_CONTEXT = 'data-context';

    self.app = app; // locally reference app object

    var _config = {
      buttonSelector: '[data-js-element=button]',
      buttonClickEvent: 'productBlock-buttonClick',
      quickViewIconSelector: '[data-js-element=quickViewIcon]',
      quickViewIconClickEvent: 'productBlock-quickViewClick',
      productDataSelector: '[data-js-element=productBlock_data]',
      colorSwatch: '.productBlock_colorSwatches_swatch',
      image: '.productBlock_image',
      imageLink: '.productBlock_link',
      rolloverWrapper: '.productBlock_imageLinkWrapper',
      productBlockRatingSelector: '.productBlock_rating',
      productBlockPriceSelector: '.productBlock_priceBlock',
      quickBuy: '.productBlock_button-productQuickbuySimple'
    };

    var _init = function(element) {
      self.element = element;
      self.button = self.element.querySelector(self.config.buttonSelector);
      self.quickViewIcon = self.element.querySelector(self.config.quickViewIconSelector);
      self.productData = self.element.querySelector(self.config.productDataSelector);
      self.colorSwatches = self.element.querySelectorAll(self.config.colorSwatch);
      self.image = self.element.querySelector(self.config.image);

      if (!self.image) {
        return;
      }

      self.imageLink = self.element.querySelector(self.config.imageLink);
      if(self.image) {
        self.originalSrc = self.image.src;
      }
      self.rolloverWrapper = self.element.querySelector(self.config.rolloverWrapper);
      self.productBlockRatingButton = self.element.querySelector(self.config.productBlockRatingSelector);
      self.productBlockPriceButton = self.element.querySelector(self.config.productBlockPriceSelector);
      self.quickBuyButton = self.element.querySelector(self.config.quickBuy);
      self.bind();
      return self;
    };

    var _bind = function() {

      if(siteObj.config.useGa4EnhancedEcom === true) {
        const itemsObjectArray = [{

          'item_name': self.element.getAttribute('data-product-name').toString().replace(/&#039;|'/g, ""),
          'item_id': self.element.getAttribute('data-product-id') ? self.element.getAttribute('data-product-id').toString() : '',
          'price': self.element.getAttribute('data-product-price'),
          'item_brand': self.element.getAttribute('data-product-brand'),
          'item_category': self.element.getAttribute('data-product-category'),
          'item_list_name': siteObj.sectionName,
          'index': self.element.getAttribute('data-product-position'),
          'brand': self.element.getAttribute('data-product-brand')
        }];

        self.element.addEventListener('click', (e) => {
          const quickBuy = self.element.querySelector('.productQuickbuy')
          if(quickBuy) {
            if(!e.target.classList.contains('productQuickbuySimple') && !e.target.classList.contains('productBlock_button-moreInfo') && !quickBuy.contains(e.target)) {
              productBlockClicked('select_item', itemsObjectArray);
            }
          } else {
            if(!e.target.classList.contains('productQuickbuySimple') && !e.target.classList.contains('productBlock_button-moreInfo')) {
              productBlockClicked('select_item', itemsObjectArray);
            }
          }
          if(e.target.classList.contains('productQuickbuySimple')) {
            productBlockClicked('add_to_cart', itemsObjectArray);
          }
        })
      }

      if (self.button) {
        self.button.addEventListener('click', self.buttonClicked);
      }
      if (self.quickViewIcon) {
        self.quickViewIcon.addEventListener('click', self.quickViewIconClicked);
      }

      if(self.productBlockRatingButton && self.productBlockRatingButton.getAttribute('data-is-link') === 'true') {
        self.productBlockRatingButton.addEventListener('click', self.goToProductPage);
      }

      if(self.productBlockPriceButton && self.productBlockPriceButton.getAttribute('data-is-link') === 'true') {
        self.productBlockPriceButton.addEventListener('click', self.goToProductPage);
      }

      for (let colorSwatch of self.colorSwatches) {
        colorSwatch.addEventListener('click', self.colorSwatchClicked.bind(null, colorSwatch));
      }

      if ($window.innerWidth > 900) {
        if (self.image.hasAttribute(DATA_ALT_SRC) && self.image.getAttribute(DATA_ALT_SRC) !== '') {
          self.rolloverWrapper.addEventListener('mouseover', self.imageRolloverIn);
          self.imageLink.addEventListener('focusin',  self.imageRolloverIn);
          self.rolloverWrapper.addEventListener('mouseleave', self.imageRolloverOut);
          self.imageLink.addEventListener('focusout', self.imageRolloverOut);

          if (!self.rolloverWrapper.hasAttribute(DATA_TRACK_HOVER)) {
            self.rolloverWrapper.setAttribute(DATA_TRACK_HOVER, "");
            self.rolloverWrapper.setAttribute(DATA_CONTEXT, "imageRollover-hover");
          }

          if (!self.imageLink.hasAttribute(DATA_TRACK_FOCUS)) {
            self.imageLink.setAttribute(DATA_TRACK_FOCUS, "");
            self.imageLink.setAttribute(DATA_CONTEXT, "imageRollover-focus");
          }
        }
      }
    };

    const productBlockClicked = (event, itemsObjectArray) => {
      app.publish('ga4tracking/record', 'ecom_event', event, siteObj.currencyType, itemsObjectArray);
    }

    var _buttonClicked = function() {
      self.app.publish(self.config.buttonClickEvent);
    };

    var _quickViewIconClicked = function(event) {
      //This currently triggers the old quickview component
      event.preventDefault();
      self.app.publish(self.config.quickViewIconClickEvent);
    };

    var _colorSwatchClicked = (colorSwatch) => {
      if (colorSwatch.hasAttribute(DATA_LINKED_SWATCH)) {
        var clickedProductId = colorSwatch.getAttribute(DATA_OPTION_ID);
        app.publish('tracking/record', 'Linked SKU Engagement',
          'Clicked away from ' + self.masterProductId, 'Clicked on ' + clickedProductId);
      }
    };

    var _imageRolloverIn = function() {
      self.image.src = self.image.getAttribute(DATA_ALT_SRC);
    };

    var _imageRolloverOut = function() {
      self.image.src = self.originalSrc;
    };

    var _goToProductPage = function() {
      $window.location.href = self.imageLink.href;
    }

    self.config = _config;
    self.init = _init;
    self.bind = _bind;
    self.imageRolloverIn = _imageRolloverIn;
    self.imageRolloverOut = _imageRolloverOut;
    self.colorSwatchClicked = _colorSwatchClicked;
    self.buttonClicked = _buttonClicked;
    self.quickViewIconClicked = _quickViewIconClicked;
    self.goToProductPage = _goToProductPage;
  };

  return productBlock;
});
